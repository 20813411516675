import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import MenuItem from '@mui/material/MenuItem'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Stack from '@mui/material/Stack'
import dayjs from 'dayjs'

import { Form, Select, SubmitButton, TextField } from 'components/Form'

export default function ExportDialog({ defaultValues, onSubmit, onClose }) {
  const [open, setOpen] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    setOpen(true)
  }, [])

  const handleChangeTab = (_, newValue) => {
    setTabIndex(newValue)
  }

  const handleSubmit = values => {
    onSubmit(values)
    onClose()
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
      <Tabs
        sx={{ m: 2 }}
        textColor="secondary"
        indicatorColor="secondary"
        value={tabIndex}
        onChange={handleChangeTab}
      >
        <Tab label="IMS 連携" />
        <Tab label="ダウンロード" />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <IMSForm
          defaultValues={defaultValues.ims}
          onSubmit={handleSubmit}
          onCancel={() => setOpen(false)}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <DownloadForm onSubmit={handleSubmit} onCancel={() => setOpen(false)} />
      </TabPanel>
    </Dialog>
  )
}

const TabPanel = ({ children, value, index }) => {
  return <Box sx={{ display: value === index ? 'block' : 'none' }}>{children}</Box>
}

const IMSForm = ({ defaultValues, onSubmit, onCancel }) => {
  const getUidSimple = digit => {
    const chars = 'ABCDEFGHJKLMNPRSTUVWXYZ0123456789'
    let result = ''
    for (let i = 0; i < digit; i++) {
      result += chars[Math.floor(Math.random() * chars.length)]
    }
    return result
  }

  const form = useForm({ defaultValues })
  const [sendAt] = form.watch(['sendAt'])
  const randomId = getUidSimple(4)

  useEffect(() => {
    if (sendAt) {
      form.setValue(
        'listName',
        `${defaultValues.listName}${dayjs(new Date(sendAt)).format('YYYYMMDDHHmm')}_${randomId}`
      )
    }
  }, [sendAt, form, defaultValues, randomId])

  const handleSubmit = values => {
    onSubmit({ type: 'IMS', values: { ...values, sendAt: new Date(values.sendAt) } })
  }

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <DialogContent>
        <Stack spacing={2}>
          <Select
            name="external_list_owner_group_id"
            label="外部リスト所有グループ"
            fullWidth
            defaultValue="IMSCS本部／BPSC"
          >
            <MenuItem value="IMSCS本部／BPSC">IMSCS本部／BPSC</MenuItem>
            <MenuItem value="IMS日経ID 抽出担当者">IMS日経ID 抽出担当者</MenuItem>
            <MenuItem value="IMS日経ID 分析担当者">IMS日経ID 分析担当者</MenuItem>
            <MenuItem value="IMS販売局 抽出担当者">IMS販売局 抽出担当者</MenuItem>
            <MenuItem value="IMS販売局 分析担当者">IMS販売局 分析担当者</MenuItem>
            <MenuItem value="IMS文事 抽出担当者">IMS文事 抽出担当者</MenuItem>
            <MenuItem value="IMSイベレジ 抽出担当者">IMSイベレジ 抽出担当者</MenuItem>
          </Select>
          <TextField
            name="listName"
            label="外部リスト名"
            fullWidth
            inputProps={{ readOnly: true }}
          />
          <TextField
            name="listPurpose"
            label="外部リスト利用用途"
            rules={{ maxLength: 200 }}
            errorText="200 文字までで入力して下さい"
            fullWidth
            multiline
          />
          <TextField
            name="sendAt"
            type="datetime-local"
            label="連携日時"
            rules={{ required: true }}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => onCancel()}>
          キャンセル
        </Button>
        <SubmitButton color="inherit" sx={{ fontWeight: 'bold' }}>
          設定する
        </SubmitButton>
      </DialogActions>
    </Form>
  )
}

const DownloadForm = ({ onSubmit, onCancel }) => {
  const form = useForm()

  const handleSubmit = values => {
    onSubmit({ type: 'DOWNLOAD', values })
  }

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <DialogContent>
        <Stack spacing={2}>
          <Select name="fieldType" label="対象カラム" fullWidth defaultValue="serial">
            <MenuItem value="serial">SERIAL ID</MenuItem>
            <MenuItem value="phone_e164_sha256">電話番号（E.164） SHA256 ハッシュ値</MenuItem>
            <MenuItem value="email_normalized_sha256">メールアドレス SHA256 ハッシュ値</MenuItem>
          </Select>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => onCancel()}>
          キャンセル
        </Button>
        <SubmitButton color="inherit" sx={{ fontWeight: 'bold' }}>
          ダウンロード
        </SubmitButton>
      </DialogActions>
    </Form>
  )
}
